<template>
  <div class="card-box">

    <div class="form">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="预约" name="0">
          <div slot="label" class="tabs_label" >
            <el-badge :hidden="appoint_num == 0" :value="appoint_num" size="mini" class="item">
              预约
            </el-badge>
          </div>
        </el-tab-pane>
        <el-tab-pane label="预约服务记录" name="1">

        </el-tab-pane>
      </el-tabs>

      <el-form
          :inline="true"
          :rules="rules"
          ref="form"
          label-width="80px"
          @submit.native.prevent
          :model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="form.name" clearable placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="手机号" prop="phoneNo">
              <el-input v-model="form.phoneNo" clearable placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="预约状态" prop="status">
              <el-select v-model="form.status" clearable placeholder="请选择预约状态">
                <el-option v-for="item in statusOption" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button type="primary" native-type="submit" @click="onSubmit(form)">查询</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="订单号" prop="orderNo">
              <el-input v-model="form.orderNo" clearable placeholder="请输入订单号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

    </div>


    <div class="card-content" style="margin-top: 12px;" >


      <template v-if="list.length != 0">
        <el-row :gutter="100">
          <el-col :span="6" v-for="item in list" :key="item.orderNo" >
            <div class="card"
                 @click="linkDeta(item)"
                 style="cursor: pointer"
                 :class="[
                   ( item.productCategory == 0 ?
                'DarkBlue' : ( item.productCategory == 1 ?
                 'LightBlue' : ( item.productCategory == 2 ?
                  'LightGreen' : 'Violet' ) )),
                  ( item.isRead ? '' : 'red' )
               ]">

              <div class="card-header">
                <div class="card-header-left">
                  <p>{{ $store.state.category.filter((items) => { return items.dictKey == item.productCategory })[0].dictValue }}</p>
                </div>
                <div class="card-header-right">
                  <p>{{ activeName == 0 ? "未确认" : "已确认" }}</p>
                </div>
              </div>

              <div class="card-item">
                <el-tooltip effect="dark" :content="item.name" placement="top">
                  <p>{{ item.name }}</p>
                </el-tooltip>
                <p>{{ item.gender == 0 ? "女" : "男" }}</p>
                <p>{{ item.phoneNo }}</p>
              </div>

              <div class="card-footer">

                <div class="card-footer-left">
                  <p class="card-footer-left-time" v-if="item.appointDate" >{{ item.appointDate }}</p>
                  <p class="card-footer-left-time" v-else>{{ item.appointmentStartDate + " ~ " + item.appointmentEndDate }}</p>
                </div>

                <!--                <div class="card-footer-right">-->
                <!--                   <el-button round size="small">认领</el-button>-->
                <!--                </div>-->

              </div>

            </div>
          </el-col>
        </el-row>

        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[12, 24, 48, 96]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </template>

      <template v-else>
        <NoList>
          <template #text>
            <p>暂无数据</p>
          </template>
        </NoList>
      </template>

    </div>

  </div>
</template>

<script>
export default {
  name: "MakeService",
  data() {
    return{
      activeName: 0,
      appoint_num: 0,
      total: 0,
      list: [],
      form: {
        pageNum: 1,
        pageSize: 12,
        name: null,
        phoneNo: null,
        status: null,
        orderNo: null,
      },
      statusOption: [
        {
          dictKey: 0,
          dictValue: "待确认"
        },
        {
          dictKey: 1,
          dictValue: "待检查"
        },
        {
          dictKey: 2,
          dictValue: "已完成"
        },
        {
          dictKey: 3,
          dictValue: "已失效"
        },
      ],
      rules: {

      }
    }
  },
  created() {

    if(this.$route.query.activeName != null) {
      this.activeName= this.$route.query.activeName;
    }
    // 未读消息
    this.get_unread_message();
    // 获取列表
    this.get_list(this.form);
  },
  methods: {
    onSubmit(formName) {
      formName.pageNum= 1;
      this.get_list(formName);
    },
    linkDeta(e) {
      this.$router.push({
        path: "/ServiceDeta",
        query: {
          id: e.serviceId,
          orderNo: e.orderNo,
          state: this.activeName
        }
      })
    },
    get_list(y) {
      if(this.activeName == 0) {
        this.$get("cs/appoint",y)
            .then(res => {
              if(res) {
                console.log(res.data.list);
                this.list= res.data.list;
                this.total= res.data.total;
              }
            })
      }else {
        this.$get("cs/appoint/history",y)
            .then(res => {
              if(res) {
                console.log(res);
                this.list= res.data.list;
                this.total= res.data.total;
              }
            })
      }
    },
    get_unread_message() {
      this.$get("cs/message")
        .then(res => {
          if(res) {
            res.data.forEach(item => {
              if(item.type == '0') {
                this.appoint_num = item.unreadCount;
              }
            })
          }
        })
    },
    // 分页
    handleSizeChange(e) {
      this.form.pageNum= 1;
      this.form.pageSize= e;
      this.get_list(this.form);
    },
    handleCurrentChange(e) {
      this.form.pageNum= e;
      this.get_list(this.form);
    },
    handleClick() {
      this.form.pageNum = 1;
      this.form.pageSize = 12;
      this.list= [];

      this.get_list(this.form);
    }
  }
}
</script>

<style lang="scss" scoped>
 @import "../../style/Service/card";
</style>
